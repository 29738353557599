import WEBHOOKS from '../constants/webhooks';

class Auth {
  authenticated: boolean;

  constructor() {
    this.authenticated = false;
    this.loadSession();
  }

  loadSession = () => {
    return new Promise((resolve, reject) => {
      if (!localStorage) {
        this.authenticated = false;
        console.log('NO SESSION');
        reject('No User Session Found');
      }

      const sessionCookie = localStorage.getItem('domyo-auth-cookie');
      const sessionUserId = localStorage.getItem('domyo-user-id');

      if (!sessionCookie || !sessionUserId) {
        this.authenticated = false;
        console.log('NO SESSION');
        reject('No User Session Found');
      } else if (
        process.env.NODE_ENV === 'development' &&
        sessionStorage &&
        sessionStorage.getItem('devSessionOpen') === 'true'
      ) {
        this.authenticated = true;
        console.log('DEV SESSION FOUND');
        resolve({ userId: sessionUserId });
      } else {
        fetch(`${WEBHOOKS.VERIFY_SESSION}?userId=${sessionUserId}&domyoAuthCookie=${sessionCookie}`)
          .then((res) => {
            if (res.status === 200) {
              this.authenticated = true;
              console.log('SESSION VERIFIED');
              if (process.env.NODE_ENV === 'development' && sessionStorage) {
                sessionStorage.setItem('devSessionOpen', 'true');
                console.log('CREATING DEV SESSION');
              }

              return res.json();
            } else {
              console.log('INVALID SESSION');
              reject(res);
              this.authenticated = false;
            }
          })
          .then((data) => resolve({ userId: sessionUserId, data }))
          .catch((e) => {
            this.authenticated = false;
            reject(e);
          });
      }
    });
  };

  register(userName: string, pass: string) {
    return new Promise((resolve, reject) => {
      const qs = `name=${userName}&pass=${pass}`;

      fetch(`${WEBHOOKS.USER_REGISTER}?${qs}`, { method: 'GET' })
        .then((res) => {
          if (res.status === 200) {
            resolve(true);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  login(userId: number, pass: string) {
    return new Promise((resolve, reject) => {
      const qs = `userId=${userId}&pass=${pass}`;

      fetch(`${WEBHOOKS.USER_SIGN_IN}?${qs}`, { method: 'GET' })
        .then((res) => {
          if (res.status === 200) {
            const authCookie = res.headers.get('domyoauthcookie');

            if (authCookie) {
              localStorage.setItem('domyo-auth-cookie', authCookie);
              localStorage.setItem('domyo-user-id', userId.toString());
            }

            this.authenticated = true;
            return res.json();
          } else {
            this.authenticated = false;
            reject(res);
          }
        })
        .then((data) => resolve(data))
        .catch((e) => {
          this.authenticated = false;
          reject(e);
        });
    });
  }

  logout(cb: Function) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
