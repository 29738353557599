import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';
import withStyles from 'react-jss';
import ProtectedRoute from './components/ProtectedRoute';
import withSplashScreen from './components/withSplashScreen';

import JSS from './AppStyles.js';

const Dashboard = lazy(() => import('./Dashboard'));
const Login = lazy(() => import('./Login'));

type Props = {
  classes: any;
};

const App: React.FC<Props> = ({ classes }: Props) => {
  return (
    <div className={classes.appContainer} style={{ boxSizing: 'border-box' }}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/(login)">
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              <Route path="/login" component={Login} />
            </Route>
            <Route>
              <ProtectedRoute exact path="/" component={Dashboard} />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default withStyles(JSS)(withSplashScreen(App));
