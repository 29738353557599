export default {
    splashContainer: {
        alignItems: 'center',
        backgroundColor: 'whitesmoke',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },

    loadingText: {
        color: 'darkgrey',
        fontSize: 26,
        margin: 0,
    },
}
