import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppState } from '../AppProvider';

export const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const state = useAppState();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (state.isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
