import React from "react";

import { AppProvider } from './AppProvider';
import AppRouter from './AppRouter';

const App: React.FC = () => (
    <AppProvider>
      <AppRouter/>
    </AppProvider>
  );

export default App;
