import React from "react"
import withStyles from 'react-jss';
import JSS from './SplashScreenStyles.js';

type Props = { classes: any };

const SplashScreen: React.FC<Props> = ({ classes }: Props) => (
    <div className={classes.splashContainer}>
        <p className={classes.loadingText}>Loading gainz...</p>
    </div>
    );
    
// @ts-ignore
export default withStyles(JSS)(SplashScreen);
