import React, { useState, useEffect } from 'react';
import Auth from '../helpers/Auth';
import SplashScreen from './SplashScreen';
import { getUserName } from '../constants/users';
import { useAppDispatch } from '../AppProvider';

type State = {
  loading: boolean;
};

const withSplashScreen = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
      Auth.loadSession()
        .then(({ userId, data }: any) => {
          // @ts-ignore
          dispatch({
            type: 'OPEN_USER_SESSION',
            payload: {
              userId,
              userName: getUserName(userId),
              userMobileDash: (data && data.dsMobile) || '',
              userDesktopDash: (data && data.dsDesktop) || '',
            },
          });
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        })
        .catch((err) => {
          setLoading(false);
        });
    }, [dispatch]);

    return loading ? <SplashScreen /> : <WrappedComponent {...props} />;
  };
};

export default withSplashScreen;
