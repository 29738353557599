export default {
    GET_LOG_DATA: 'https://hook.integromat.com/cwuqf4awo6rgh1yj5a4maupr9truz4gb',
    GET_USERS: 'https://hook.integromat.com/uo9cuoan0odop7o24wbh4ud7sb1abid6',
    FAT_SECRET_SYNC: 'https://hook.integromat.com/bbw6yawpjlh1uxb0pq7hsr75cay9mw2x',
    LOG_SUBMIT: 'https://hook.integromat.com/zufab3k8w7ctftez7jsr1r7ytr33h4xg',
    LOG_SUBMIT_V2: 'https://hook.integromat.com/6ah0t3irpgddlfdrt807ythm27c9cebh',
    USER_SIGN_IN: 'https://hook.integromat.com/evydutypf27q1k6dj4mr3r7pfu3iibcw',
    USER_REGISTER: 'https://hook.integromat.com/1c5q96eygpqke6pmvb2qu6ef7ygfv94w',
    VERIFY_SESSION: 'https://hook.integromat.com/iemvlwdp1qmmmxp8vpbu5pk3g90b9i4q',
};
