import React from 'react';

const initialAppState = {
  userId: null,
  userName: null,
  isAuthenticated: false,
  userMobileDash: '',
  userDesktopDash: '',
};

const AppStateContext = React.createContext(initialAppState);
const AppDispatchContext = React.createContext({});

const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'OPEN_USER_SESSION':
      return {
        ...state,
        isAuthenticated: true,
        userName: action.payload.userName,
        userId: action.payload.userId,
        userMobileDash: action.payload.userMobileDash,
        userDesktopDash: action.payload.userDesktopDash,
      };
    default: {
      return state;
    }
  }
};

export const useAppState = () => {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
};

export const useAppDispatch = () => {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider');
  }
  return context;
};

export const AppProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(appReducer, initialAppState);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};
