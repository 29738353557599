
export default {
    appContainer: {
        padding: 0,
        height:'100%',
        width: '100%',
    },

    '@media (min-width: 1080px)': {
        appContainer: {
            maxWidth: 980,
            margin: '0 auto',
        },
    },
};